/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import Chip from "@mui/material/Chip";
import { useContext, useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import SupportModal from "components/Modal/SupportModal";
import Avatar from "@mui/material/Avatar";
import ForumIcon from "@mui/icons-material/Forum";
import { alpha } from "@mui/material";
import { SupportContext } from "../../context/supportContext";
import "./index.css";

const AdminNavbar = (props) => {
  const [loggedUser, setUser] = useState(false);
  const { openModal, isOpen  } = useContext(SupportContext);
  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setUser(user);
  }, []);
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <hr
          style={{
            height: "2px",
            borderWidth: 0,
            color: "gray",
            backgroundColor: "gray",
          }}
        />

        <Container fluid>
          {props.brandText === "Dashboard" ? (
            <Link
              style={{
                borderRadius: "8px",
                padding: "5px",
                color: "#fff",
                textTransform: "uppercase",
              }}
              to={props}
            >
              <b>{`${props.brandText}`}</b>
            </Link>
          ) : (
            <Link
              style={{
                borderRadius: "6px",
                padding: "9px 15px",
                fontSize: "16px",
                color: "#32325D",
                fontFamily: "open sans",
                fontWeight: 400,
                lineHeight: "22px",
              }}
              className="breadCrumb"
              to={props}
            >
              <i
                style={{ color: "#FF6700", marginLeft: "5px" }}
                className="fas fa-home"
              ></i>{" "}
              {`/ ${props.brandText}`}
            </Link>
          )}
          <Nav
            style={{ display: "flex", flexDirection: "column" }}
            className="align-items-center d-none d-md-flex"
            navbar
          >
            <UncontrolledDropdown nav>
              <div className="pr-0 navbar-headerSupport" nav>
                <ForumIcon
                  style={{ color: "#fff", marginRight: 30, cursor: "pointer" }}
                  onClick={() => openModal()}
                />

                <Media className="align-items-center">
                  <Avatar
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      backgroundColor: "#ced4da",
                    }}
                    alt={loggedUser?.name}
                    src="/"
                  />
                  {/* </span> */}
                  <Media className="ml-2 d-none d-lg-block">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: 56,
                      }}
                    >
                      <span
                        style={{
                          width: "120px",
                          fontFamily: "open sans",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#FFFFFF",
                        }}
                      >
                        {loggedUser?.name}
                      </span>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#ffffff",
                          fontWeight: "500",
                          backgroundColor: alpha("#e9ecef", 0.2),
                          height: "20px",
                          width: "fit-content",
                          textAlign: "center",
                          borderRadius: "6px",
                          padding: "0px 8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {loggedUser?.adminType}
                      </div>
                    </div>
                  </Media>
                </Media>
              </div>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      {isOpen && (
        <SupportModal />
      )}
    </>
  );
};

export default AdminNavbar;
