/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React from "react";
import BookIcon from "@mui/icons-material/Book";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import ProfilecardTutee from "components/Profilecard/Profilecardtutee.js";
import api from "services/api";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import "./index.css";
import { async } from "@firebase/util";
import { JobStatusEnum } from "helpers/common-helper";
import ChildrenBlock from "components/ChildrenBlock";
import Schedule from "components/Schedule/Schedule";
import { calculateAge } from "helpers/common-helper";

class TableRow extends React.Component {
  state = { expanded: false };

  toggleExpander = (e) => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { bill, invoices } = this.props;
    return [
      <>
        <tr>
          <>
            <th scope="row">
              <Media className="align-items-center">
                <BookIcon
                  sx={{
                    color: bill?.job[0]?.course[0]?.courseColor
                      ? bill?.job[0]?.course[0]?.courseColor
                      : " #ced4da",
                    width: "2.083vw",
                    height: "2.083vw",
                  }}
                />

                <td style={{ width: "15%", border: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <a
                      style={{ fontSize: "0.729vw" }}
                      href={`/#/admin/TutionDetails/${bill?.job?.[0]?.id}`}
                    >
                      {bill?.invoices?.[0]?.totalClasses?.[0]?.courseName}
                    </a>
                    <span style={{ color: "grey", fontSize: "80%" }}>
                      {bill?.invoices?.[0]?.totalClasses?.[0]?.courseLvl}
                    </span>
                  </div>
                </td>
              </Media>
            </th>
            <td style={{ fontSize: "80%" }}>
              {bill?.job[0]?.courseDuration || "N/A"}
            </td>
            <td style={{ fontSize: "80%" }}>${bill?.job[0]?.priceperHour}</td>
            <td style={{ fontSize: "80%" }}>
              {bill?.totalClassDuration} Hours
            </td>
            <td style={{ fontWeight: "bold", fontSize: "70%" }}>
              {bill?.invoices[0]?.month}
            </td>
            <td>{bill?.invoices[0]?.noOfClasses || 0}</td>
            <td>${bill?.invoices[0]?.amount}</td>
            <td>
              ${bill?.invoices[0]?.amount - bill?.invoices[0]?.remainingAmount}
            </td>
            <td>${bill?.invoices[0]?.remainingAmount}</td>
            <td>${bill.invoices[0]?.salary}</td>
            {/* <td>
              {bill.invoices[0]?.jobDetails?.postponed}
            </td> */}
            <td>{bill.invoices[0]?.paymentStatus}</td>
            <td>{JobStatusEnum(bill?.job[0]?.jobStatus).view}</td>
            <td className="text-right"></td>
            <td className="text-right">
              {!this.state.expanded ? (
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingRight: "20px",
                  }}
                  onClick={() => this.toggleExpander()}
                >
                  <i className="fas fa-chevron-down"></i>
                </button>
              ) : (
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => this.toggleExpander()}
                >
                  {" "}
                  <i className="fas fa-chevron-up"></i>
                </button>
              )}
            </td>
          </>
        </tr>
        {this.state.expanded
          ? invoices.length &&
            invoices.map(
              (inv, ind) =>
                ind !== 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: "bold", fontSize: "70%" }}>
                      {inv.month}
                    </td>
                    <td>{bill?.invoices[ind]?.noOfClasses}</td>

                    <td>{bill?.invoices[ind]?.amount}</td>
                    <td>
                      $
                      {bill?.invoices[ind]?.amount -
                        bill?.invoices[ind]?.remainingAmount}
                    </td>
                    <td style={{ fontSize: "70%" }}>
                      {bill?.invoices[ind]?.remainingAmount}
                    </td>
                    <td style={{ fontSize: "70%" }}>
                      {bill.invoices[ind]?.salary}
                    </td>
                    <td style={{ fontSize: "70%" }}>
                      {bill.invoices[ind]?.paymentStatus}
                    </td>
                    <td>{JobStatusEnum(bill.job[0]?.jobStatus).view}</td>

                    {/* <td></td> */}
                    <td className="text-right"></td>
                    <td className="text-right"></td>
                  </tr>
                )
            )
          : null}
      </>,
      ,
    ];
  }
}

const Tables = (props) => {
  const [tab, setTab] = useState("InActive");
  const [rowOpen, setRowOpen] = useState(false);
  const [userId, setUserId] = useState(props.match.params.id);
  const [user, setUser] = useState({});
  const [allJobs, setAllJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const [jobDeletion, setJobDeletion] = useState(props.match.params.id);
  const [openModel, setOpenModel] = useState(false);
  const [isOpen3, setOpen3] = useState(false);
  const [billing, setBilling] = useState([]);
  const [actievBilling, setActievBilling] = useState([]);
  const [loggedUser, setLoggedInUser] = useState([]);

  async function getBilling(tab, startDate) {
    try {
      let paymentMethod = "";
      if (tab == 1) {
        paymentMethod = "GIRO";
      }
      if (tab == 2) {
        paymentMethod = "Debit/Credit%20Card";
      }
      if (tab == 3) {
        paymentMethod = "Bank%20Transfer";
      }
      const billing = await api.getBilling(`?tuteeId=${userId}&jobDetail=true`);
      setBilling(billing.data);
    } catch (e) {}
  }

  useEffect(async () => {
    const activeBills = [];
    if (billing.result) {
      for (let bill of billing?.result) {
        if (bill?.job[0]?.jobStatus === 2) {
          for (let invoice of bill?.invoices) {
            if (invoice.payments.length > 0) {
              for (let payment of invoice.payments) {
                let paymentData = { ...payment, job: bill?.job[0] };
                activeBills.push(paymentData);
              }
            }
          }
        }
      }
    }
    let sortedBills = await activeBills?.sort((a, b) => {
      let date1 = new Date(a?.date).getTime();
      let date2 = new Date(b?.date).getTime();

      if (date1 > date2) return -1;
      if (date1 < date2) return 1;
      return 0;
    });
    setActievBilling(sortedBills);
  }, [billing?.result]);
  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    getBilling();
    setLoggedInUser(user);
    getAllJobs();
    getUserSchedule();
    getUsersById();
    await getRequestForJobTermination();
  }, []);
  async function getRequestForJobTermination() {
    try {
      const jobTermination = await api.getAllJobs("");
      if (!jobTermination.length) {
        await setJobTermination(jobTermination.data);
      }
    } catch (e) {}
  }

  async function getUserSchedule() {
    try {
      const schedule = await api.getUserSchedule(`?tuteeId=${userId}`);

      const newSchedule = schedule.data.map((sch) => {
        return {
          ...sch,
          color: sch.courseColor,
          oldDate: sch?.date,
          oldTime: sch?.time,
          date: sch?.newDate ?? sch?.date,
        };
      });
      setSchedule(newSchedule);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function getAllJobs() {
    try {
      setIsLoading(true);
      const allJobsApi = await api.getAllJobs(`?tuteeId=${userId}`);
      if (!allJobs.length) {
        setAllJobs(allJobsApi.data);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }
  async function getUsersById() {
    try {
      const user = await api.getUserById(
        userId,
        "?userType=Tutee&reviewDetail=true&qualification=true&jobDetail=true"
      );
      setTab(user?.data?.user?.giroStatus);
      setUser(user.data);
    } catch (e) {}
  }

  async function editUser(userId, data) {
    try {
      const user = await api.editUser(userId, data);
      await setUser(user.data);
      getUsersById();
    } catch (e) {}
  }

  async function giroTabEditUser(tabType) {
    try {
      if (tabType === 0) {
        const giroStatus = "InActive";
        let userData1 = { user: { giroStatus } };
        const updatedUesr = await api.editUser(userId, userData1);
        await setUser(updatedUesr.data);
        setTab("InActive");
      }
      if (tabType === 1) {
        const giroStatus = "Pending";
        let userData1 = { user: { giroStatus } };
        const updatedUesr = await api.editUser(userId, userData1);
        await setUser(updatedUesr.data);
        setTab("Pending");
      }
      if (tabType === 2) {
        const giroStatus = "Active";
        let userData1 = { user: { giroStatus } };
        const updatedUesr = await api.editUser(userId, userData1);
        await setUser(updatedUesr.data);
        setTab("Active");
      }
      // setTab(tab)
    } catch (e) {}
  }

  function closeCalendar() {
    setOpen3(false);
    getUserSchedule();
  }

  function getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }
  async function viewJob(job) {
    const data = {
      adminId: loggedUser._id,
      jobId: job?._id,
      activityType: "View Tuition",
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }
  async function viewProfile(user, type) {
    const data = {
      adminId: loggedUser._id,
      userId: user._id,
      activityType: "View Profile",
      message: type,
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }

    function createdAtMonthCal(createdAt) {
      const getDate1 = new Date();
      const getDate2 = new Date(createdAt);
    
      const year1 = getDate1.getFullYear();
      const year2 = getDate2.getFullYear();
    
      const month1 = getDate1.getMonth();
      const month2 = getDate2.getMonth();
    
      const day1 = getDate1.getDate();
      const day2 = getDate2.getDate();
    
      let yearDiff = year1 - year2;
      let monthDiff = month1 - month2;
      let dayDiff = day1 - day2;
    
      // Adjust for negative months or days due to year transition
      if (dayDiff < 0) {
        monthDiff -= 1;
        dayDiff += new Date(year2, month2 + 1, 0).getDate(); // Add days in the previous month
      }
    
      if (monthDiff < 0) {
        yearDiff -= 1;
        monthDiff += 12;
      }
    
      if (yearDiff > 0) {
        return yearDiff === 1 ? `${yearDiff} Year ago` : `${yearDiff} Years ago`;
      } else if (monthDiff > 0) {
        return monthDiff === 1 ? `${monthDiff} Month ago` : `${monthDiff} Months ago`;
      } else if (dayDiff > 0) {
        return dayDiff === 1 ? `${dayDiff} Day ago` : `${dayDiff} Days ago`;
      } else {
        return "Today";
      }
    }
    

  function convertDate(date) {
    var today = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      today.getDate() +
      " " +
      monthNames[today.getMonth()] +
      ", " +
      today.getFullYear()
    );
  }

  const calcluateTotalTuteeSpended = () => {
    let totalSpended = 0;
    actievBilling.map((item) => (totalSpended += Number(item.amount)));

    return totalSpended;
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <div className="mt--7" fluid>
        {/* Table */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "3.125vw",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "26.042vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <ProfilecardTutee
                user={user}
                totalSpended={calcluateTotalTuteeSpended()}
                editUser={editUser}
                getUsersById={getUsersById}
              />
            </div>
            <div style={{ marginTop: "1.563vw" }}>
              <ChildrenBlock children={user?.user?.children} />
            </div>
          </div>
          <div
            xs={12}
            md={7}
            lg={7}
            xl={7}
            style={{
              width: "52.813vw",
              height: "40.833vw",
              marginLeft: "1.563vw",
              marginRight: "3.125vw",
            }}
          >
            <div
              style={{ backgroundColor: "#FFFFFF", borderRadius: "0.625vw" }}
              className="card-profile shadow"
            >
              <div
                className=" text-center border-0"
                style={{
                  padding: "1.042vw 1.563vw",
                  text: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "0.521vw",
                }}
              >
                <h3 className="mb-0">Schedule</h3>
                <div>
                  {/* <Link> */}
                  {/* <div
                    onClick={() => setOpen3(true)}
                    style={{
                      fontSize: "0.8vw",
                      width: "120px",
                      marginLeft: "-5px",
                    }}
                    className="buttonTabs1"
                  >
                    <i
                      style={{ paddingRight: "5px", marginLeft: "-7px" }}
                      className="far fa-calendar-alt"
                    ></i>
                    Reschedule class
                  </div> */}
                  {/* </Link> */}
                </div>
              </div>
              <div
                style={{
                  height: "37.2vw",
                  display: "flex",
                }}
              >
                <Schedule
                  schedule={schedule}
                  getUserSchedule={getUserSchedule}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "1.563vw",
            marginLeft: "3.125vw",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "100vw",
              height: "22.448vw",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <div
                className="shadow"
                style={{
                  width: "80.729vw",
                  height: "22.448vw",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0.625vw",
                }}
              >
                <div
                  className="border-0"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div
                    style={{
                      paddingLeft: "1.563vw",
                      paddingBottom: "1.250vw",
                      paddingTop: "1.302vw",
                      fontSize: "0.938vw",
                      fontWeight: 600,
                      color: "#454545",
                    }}
                  >
                    All posted tuitions
                  </div>
                </div>
                <div style={{ height: "17.240vw", overflowY: "scroll" }}>
                  <Table
                    className="align-items-center table-flush PrimaryScrollQualification"
                    responsive
                  >
                    <thead
                      className="thead-light sticky-top PrimaryScrollQualification"
                      style={{ top: "-3px" }}
                    >
                      <tr>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            width: "170px",

                            paddingRight: "0rem",
                          }}
                        >
                          TUITION
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        >
                          POSTED BY
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            width: "200px",
                          }}
                        >
                          Child
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        >
                          START DATE
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        >
                          DURATION
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        >
                          RATE
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        >
                          TUTOR REQUESTS
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        >
                          Job Status
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            paddingRight: "0rem",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr
                          style={{
                            width: "100%",
                            height: "100%",
                            marginLeft: "10rem",
                          }}
                        >
                          <td colSpan={9}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ReactLoading
                                type={"spin"}
                                color={"#ff6700"}
                                height={30}
                                width={30}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : allJobs?.length > 0 ? (
                        allJobs
                          ?.filter((job) => job?.jobStatus === 1)
                          .map((user) => (
                            <tr>
                              <td scope="row">
                                <Media className="align-items-center">
                                  <BookIcon
                                    sx={{
                                      color: user?.course[0]?.courseColor
                                        ? user.course[0].courseColor
                                        : " #ced4da",
                                      width: "2.083vw",
                                      height: "2.083vw",
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      textAlign: "left",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    <Link
                                      className="mb-0 "
                                      style={{
                                        fontSize: "0.729vw",
                                        fontWeight: 600,
                                      }}
                                      to={`/admin/TutionDetails/${user?._id}`}
                                      onClick={() => viewJob(user)}
                                    >
                                      {user.course[0]?.courseName}
                                    </Link>
                                    <span
                                      style={{
                                        color: "#9a9a9a",
                                        fontSize: "0.625vw",
                                      }}
                                    >
                                      {user.courseLevel}{" "}
                                    </span>
                                  </div>
                                </Media>
                              </td>
                              <td scope="row">
                                <Media className="align-items-center">
                                  <Avatar
                                    style={{
                                      width: "1.979vw",
                                      fontSize: "1.042vw",
                                      height: "1.979vw",
                                      backgroundColor: "#ced4da",
                                    }}
                                    alt={user.tutee[0].name}
                                    src="/"
                                  />

                                  <Media>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "left",
                                        marginLeft: "0.521vw",
                                      }}
                                    >
                                      <Link
                                        className="mb-0 "
                                        style={{
                                          fontSize: "0.729vw",
                                          fontWeight: 600,
                                        }}
                                        to={`/admin/Tutee/${user.tutee[0]._id}`}
                                        onClick={() =>
                                          viewProfile(user.tutee[0], "tutee")
                                        }
                                      >
                                        {user.tutee[0].name}
                                      </Link>
                                      <span
                                        style={{
                                          color: "#9a9a9a",
                                          fontSize: "0.625vw",
                                        }}
                                      >
                                        {createdAtMonthCal(user?.createdAt)}
                                      </span>
                                    </div>
                                  </Media>
                                </Media>
                              </td>
                              <td scope="row">
                                {user.child[0]?.name ? (
                                  <>
                                    <Media className="align-items-center">
                                      <Avatar
                                        style={{
                                          width: 38,
                                          height: 38,
                                          backgroundColor: "#ced4da",
                                        }}
                                        alt={user.child[0]?.name}
                                        src="/"
                                      />

                                      <Media>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            textAlign: "left",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <span
                                            className="mb-0 "
                                            style={{
                                              fontSize: "100%",
                                              fontWeight: 600,
                                              color: "#525f7f",
                                            }}
                                          >
                                            {user.child[0]?.name ?? "--"}
                                          </span>
                                          <div className="postpaidTag">{`${calculateAge(
                                            user.child[0]?.dateOfBirth
                                          )} years old`}</div>
                                        </div>
                                      </Media>
                                    </Media>
                                  </>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td style={{ fontSize: "0.729vw" }}>
                                {convertDate(user.startDate)}
                              </td>
                              <td style={{ fontSize: "0.729vw" }}>
                                {user.courseDuration}
                              </td>
                              <td style={{ fontSize: "0.729vw" }}>
                                {`$${user.priceperHour} / hour`}
                              </td>
                              <td>
                                {user.requests?.length ?? 0} Requests
                                {/* {user.requests.map((req) => (
                                <div className="avatar-group">
                                  <a
                                    style={{
                                      height: "1.302vw",
                                      width: "1.302vw",
                                    }}
                                    className="avatar avatar-sm"
                                    href="#pablo"
                                    id="tooltip742438047"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img
                                      style={{
                                        height: "1.302vw",
                                        width: "1.302vw",
                                      }}
                                      alt="..."
                                      className="rounded-circle"
                                      src={
                                        req.tutorDetail[0].photoUrl
                                          ? req.tutorDetail[0].photoUrl
                                          : require("../../assets/img/theme/dummyImg.png")
                                              .default
                                      }
                                    />
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip742438047"
                                  >
                                    {req.tutorDetail[0].name}
                                  </UncontrolledTooltip>
                                </div>
                              ))} */}
                              </td>
                              <td>
                                {JobStatusEnum(user?.jobStatus).view}
                                {/* <div
                                  style={{
                                    fontSize: "12px",
                                    color:
                                      user?.jobStatus === 1 ? "#000" : "#fff",
                                    fontWeight: "600",
                                    backgroundColor:
                                      user?.jobStatus === 1
                                        ? "#e9ecef"
                                        : user?.jobStatus === 5
                                        ? "red"
                                        : "#12BF0F",
                                    height: "28px",
                                    width: "150px",
                                    textAlign: "center",
                                    borderRadius: "30px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {(user?.jobStatus === 1 && (
                                    <div>Posted</div>
                                  )) ||
                                    (user?.jobStatus === 2 && (
                                      <div>Ongoing</div>
                                    )) ||
                                    (user?.jobStatus === 3 && (
                                      <div>Completed</div>
                                    )) ||
                                    (user?.jobStatus === 4 && (
                                      <div>In Dispute OVER HERE HELLO</div>
                                    )) ||
                                    (user?.jobStatus === 5 && (
                                      <div>Terminated</div>
                                    ))}
                                </div> */}
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => {
                                        setOpenModel(true);
                                        setJobDeletion(user._id);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <Modal
                                className="modal-dialog-centered jobPost-delete-model"
                                isOpen={openModel}
                                toggle={() => setOpenModel(!openModel)}
                              >
                                <div className="DeleteModalMain">
                                  <div className="ModalDeleteText">
                                    Are you sure about deleting this job post?
                                  </div>
                                  <div className="DeleteButtons">
                                    <button
                                      className="DeleteCancle"
                                      onClick={() => setOpenModel(false)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="DeleteOk"
                                      onClick={() => {
                                        api.deleteJob(jobDeletion);
                                        getAllJobs();
                                        setOpenModel(false);
                                      }}
                                    >
                                      {isLoading ? (
                                        <ReactLoading
                                          type={"spin"}
                                          color={"#ffffff"}
                                          height={30}
                                          width={30}
                                        />
                                      ) : (
                                        "Yes"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            </tr>
                          ))
                      ) : (
                        <tr style={{ width: "100%" }}>
                          <td colSpan={5}>
                            <p
                              style={{
                                marginTop: "-35%",
                                fontSize: "0.625vw",
                                textAlign: "center",
                              }}
                            >
                              No Posted Tuitions
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginLeft: "3.125vw",
            marginTop: "1.563vw",
            width: "80.729vw",
          }}
        >
          <div>
            <div
              style={{ backgroundColor: "#FFFFFF", borderRadius: "0.625vw" }}
              className="shadow"
            >
              <div
                className="border-0"
                style={{
                  margin: "0px",
                  padding: "1.250vw 1.563vw",
                  height: "3.802vw",
                }}
              >
                <h3
                  style={{
                    minHeight: "1.563vw",
                    fontSize: "0.938vw",
                    color: "#454545",
                    fontWeight: 600,
                  }}
                  className="mb-0"
                >
                  All tutions
                </h3>
              </div>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">TUITION</th>
                    <th scope="col">Duration </th>
                    <th scope="col">Hourly Rate</th>
                    <th scope="col">CLASS Duration</th>
                    <th scope="col">Month</th>
                    <th scope="col">Total Classes</th>

                    <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                      Total Bill
                    </th>
                    <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                      Paid
                    </th>
                    <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                      Pending
                    </th>
                    <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                      Tutor Salary
                    </th>

                    <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                      Status
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: "0.521vw",
                        fontWeight: 600,
                        paddingRight: "0rem",
                      }}
                    >
                      Job Status
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#EFFFF8" }}>
                  {billing?.result?.map((bill, index) => (
                    <TableRow
                      bill={bill}
                      key={index}
                      index={index + 1}
                      invoices={bill?.invoices}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100vw",
            height: "22.448vw",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            className="card-profile shadow"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "0.625vw",
              width: "30.350vw",
              minHeight: "16.927vw",
              marginLeft: "3.125vw",
              marginTop: "1.563vw",
            }}
          >
            <div className="pt-0 " style={{ padding: "0px" }}>
              <div
                style={{
                  paddingLeft: "1.563vw",
                  paddingBottom: "1.250vw",
                  paddingTop: "1.302vw",
                  fontSize: "0.938vw",
                  fontWeight: 600,
                  color: "#454545",
                }}
              >
                Payment methods
              </div>
              <hr style={{ marginBottom: "1.146vw", marginTop: "0px" }}></hr>

              <div style={{ padding: "0px 1.563vw" }}>
                <div
                  className="align-items-center"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "0.833vw",
                        marginLeft: "0.625vw",
                        color: "#454545",
                      }}
                    >
                      GIRO Interbank
                    </div>
                  </div>
                  <div style={{ marginLeft: "2px" }} className="buttonStyle">
                    <a onClick={async () => giroTabEditUser(0)}>
                      <div
                        style={{ width: "3.5vw", height: "1.25vw" }}
                        className={tab === "InActive" ? "Active" : "InActive"}
                      >
                        Inactive
                      </div>
                    </a>
                    <a onClick={async () => giroTabEditUser(1)}>
                      <div
                        style={{ width: "3.5vw", height: "1.25vw" }}
                        className={tab === "Pending" ? "Active" : "InActive"}
                      >
                        Pending
                      </div>
                    </a>
                    <a onClick={async () => giroTabEditUser(2)}>
                      <div
                        style={{ width: "3.5vw", height: "1.25vw" }}
                        className={tab === "Active" ? "Active" : "InActive"}
                      >
                        {" "}
                        Active
                      </div>
                    </a>
                  </div>

                  {user?.user?.recurringPaymentMethod === "Giro" ? (
                    <div
                      style={{
                        height: "1.042vw",
                        width: "1.042vw",
                        marginLeft: "0.32vw",
                        marginTop: "5px",
                      }}
                    >
                      <img
                        alt="..."
                        src={
                          require("../../assets/img/theme/Vector.png").default
                        }
                      />
                    </div>
                  ) : null}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "1.510vw",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "0.833vw",
                      marginLeft: "0.625vw",
                      color: "#454545",
                    }}
                    className="mb-0 "
                  >
                    Credit/Debit Card
                  </span>

                  <div
                    style={{
                      height: "1.042vw",
                      width: "1.042vw",
                      marginLeft: "auto",
                      marginTop: "5px",
                    }}
                  >
                    {user?.user?.recurringPaymentMethod === "Card" ? (
                      <img
                        alt="..."
                        src={
                          require("../../assets/img/theme/Vector.png").default
                        }
                      />
                    ) : null}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: "1.510vw",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.833vw",
                      marginLeft: "0.625vw",
                      color: "#454545",
                      marginTop: "0.521vw",
                    }}
                  >
                    None
                  </div>
                  <div
                    style={{
                      height: "1.042vw",
                      width: "1.042vw",
                      marginLeft: "auto",
                      paddingTop: "5px",
                    }}
                  >
                    {user?.user?.recurringPaymentMethod === "None" ? (
                      <img
                        alt="..."
                        src={
                          require("../../assets/img/theme/Vector.png").default
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginLeft: "1.563vw",
              marginTop: "1.563vw",
              width: "48.750vw",
              borderRadius: "0.625vw",

              // maxWidth: "50.406vw !important",
              maxHeight: "22.448vw !important",
              minWidth: "26.406vw !important",
              minHeight: "22.448vw !important",
            }}
          >
            <div
              className="card-profile shadow"
              style={{
                width: "100%",
                height: "22.448vw",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.625vw",
              }}
            >
              <CardHeader
                className=" text-left"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div
                  style={{
                    paddingLeft: "1.563vw",
                    paddingBottom: "1.250vw",
                    paddingTop: "1.302vw",
                    fontSize: "0.938vw",
                    fontWeight: 600,
                    color: "#454545",
                  }}
                >
                  Billing history
                </div>
              </CardHeader>
              <div className="PrimaryScrollQualification1122">
                {actievBilling.length ? (
                  actievBilling?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "0.990vw",
                        marginBottom: "0.260vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            height: "2.240vw",
                            color: "rgb(163, 163, 163)",
                            width: "0.260vw",
                            borderRadius: "11px",
                            marginRight: "0.521vw",
                            marginLeft: "1.563vw",
                            backgroundColor: "rgb(163, 163, 163)",
                          }}
                        ></div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                color: "#454545",
                                fontSize: "0.729vw",
                                fontWeight: 600,
                              }}
                            >
                              {item?.job?.courseName}{" "}
                            </div>
                            <div
                              style={{
                                color: "#9A9A9A",
                                fontSize: "0.625vw",
                                fontWeight: 400,
                              }}
                            >
                              - {item?.job?.courseLvl}
                            </div>
                          </div>
                          <div
                            style={{
                              color: "#5E72E4",
                              fontSize: "0.729vw",
                              fontWeight: 600,
                            }}
                          >
                            {item.paymentMethod}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          marginRight: "1.563vw",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "0.938vw",
                            color: "#FF7272",
                          }}
                          className="mb-0 text-sm"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          ${item.amount}
                        </div>
                        <div
                          style={{
                            marginLeft: "0.521vw",
                            fontSize: "0.8vw",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "0.625vw",
                              color: "#9A9A9A",
                            }}
                          >
                            {" "}
                            Payed on
                          </div>
                          <div
                            style={{
                              color: "#454545",
                              fontSize: "0.729vw",
                              fontWeight: "600",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            {convertDate(item.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "4rem",
                      color: "red",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No billig history
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        className="modal-dialog-centered"
        isOpen={isOpen3}
        size="lg"
        style={{ maxWidth: "1000px", height: "80%", width: "100%" }}
        toggle={() => setOpen3(!isOpen3)}
      > */}
      {isOpen3 && (
        <Schedule
          getUserSchedule={getUserSchedule}
          schedule={schedule}
          closeCalendar={() => closeCalendar()}
        />
      )}
      {/* </Modal> */}
    </>
  );
};

export default Tables;
