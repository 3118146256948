import React, { useState, useEffect, useContext } from "react";
import { MsgBox } from "./msgBox";
import MessageServiceProvider from "./messageBoxFBHook";
import { adminService } from "./inBoxFBHook";
import Avatar from "@mui/material/Avatar";
import { SupportContext } from "../../../context/supportContext";
export const MyChat = ({
  admin,
  messageId = null,
  openMsgModal,
  setOpenMsgModal,
  scroll,
  setScroll,
  chatRef,
}) => {
  const [doc, setDoc] = useState({});
  const { data, isLoading } = adminService();
  const dateFromTimestamp = (timestamp) => {
    let timestamp1 = new Date(timestamp * 1);
    timestamp1 = timestamp1?.toLocaleTimeString();
    timestamp1 = timestamp1.substr(0, 4) + timestamp1.substr(7, 9);
    let timestamp2 = new Date(timestamp * 1);
    let month1 = timestamp2.getMonth();
    let year1 = timestamp2.getFullYear();
    year1 = String(year1).substr(2, 3);
    timestamp2 = timestamp2?.getDate();
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    month1 = month[month1];
    return timestamp1 + " - " + timestamp2 + " " + month1 + ", " + year1;
  };
  const { closeModal, modalInfo, resetModal } = useContext(SupportContext);
  useEffect(() => {
    if (messageId && modalInfo?.adminId && data && data.length) {
      const temp = data?.find((doc) => doc?.id === messageId);
      setDoc(temp);
      setOpenMsgModal(true);
    }
  }, [messageId, data]);

  return (
    <>
      {openMsgModal === false ? (
        data?.map((doc, i = 0) => {
          return (
            <div key={doc?.id}>
              <div
                className="supportChatMyInboxDivider"
                onClick={() => {
                  setOpenMsgModal(true);
                  setDoc(doc);
                }}
              >
                <div className="supportChatMyInbox1">
                  <div className="ProfilePicSuppMyInbox">
                    <Avatar
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 10,
                        backgroundColor: "#ced4da",
                      }}
                      alt={doc?.appUserName}
                      src={doc?.appUserPhotoUrl}
                    />
                  </div>
                  <div className="ProfileMsgInfoSuppMyInbox">
                    <div className="ChatInfoSuppMyInbox">
                      <div className="ChatSenderTitleSuppMyInbox">
                        <div className="ChatSenderSuppMyInbox">
                          {doc?.appUserName}
                        </div>
                        {doc?.appUserType === "Tutee" ? (
                          <div className="TuteeLabelCoverBox support-userType">
                            <div className="TuteeLabelCover">TUTEE</div>
                          </div>
                        ) : (
                          <div className="TUTORLabelSuppChatBox support-userType">
                            <div className="TUTORLabelSuppChat">TUTOR</div>
                          </div>
                        )}
                      </div>
                      <div className="ChatSentTimeSuppMyInbox">
                        {dateFromTimestamp(doc?.timestamp)}
                      </div>
                    </div>
                    <div className="ChatMsgSuppMyInbox">
                      <p className="ChatMsgSuppMyInboxText">{doc?.lastMsg}</p>
                      {doc.unreadCountAdmin > 0 && (
                        <div className="unReadCountBlock">
                          {doc.unreadCountAdmin}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="SuppDivider">
                <div className="divider"></div>
              </div>
            </div>
          );
        })
      ) : (
        <MessageServiceProvider>
          <MsgBox
            chatType="myChat"
            admin={admin}
            chatInfo={doc}
            chatId={doc.id}
            resetModal={resetModal}
            closeModal={() => {
              setOpenMsgModal(false);
              resetModal();
              setDoc({});
            }}
          />
        </MessageServiceProvider>
      )}
    </>
  );
};
