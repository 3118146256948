/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  CardBody,
  Modal,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Map from "../../components/Map/Map";
import Schedule from "components/Schedule/Schedule";
import Chat from "components/ChatLog/ChatLog";
import api from "../../services/api";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactStars from "react-rating-stars-component";
import Loading from "react-loading";
import ReactLoading from "react-loading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import ConversationServiceProvider from "../../views/pages/Chat/conversationFBHook";
import { Conversation } from "../../views/pages/Chat/conversation";
import SupportModal from "../../components/Modal/SupportModal";
import "./index.css";
import { JobStatusEnum } from "helpers/common-helper";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#FF8A3C",
      darker: "#FF8A3C",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const Tables = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [conversationModalOpen, setConversationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [job, setJobById] = useState("");
  const [loggedUser, setLoggedUser] = useState({});
  const [jobId, setJobId] = useState(props.match.params.id);
  const [review, setReview] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [billing, setBilling] = useState([]);
  const [requests, setRequests] = useState([]);
  const [qualification, setQualification] = useState([]);
  const currentdate = new Date(job.startTime * 1000);
  const hours = currentdate.getHours();
  const minutes = "0" + currentdate.getMinutes();
  const formattedTime = hours + ":" + minutes.substr(-2);
  // Time stamp to time ends

  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setLoggedUser(user);
    setIsLoading(true);
    getUserSchedule();
    getBilling();
    getJobById();
    getReview();
    getAllRequests();
    getQualification();
  }, []);

  async function getBilling(tab, startDate) {
    try {
      const billing = await api.getBilling(`?jobId=${jobId}&jobDetail=true`);
      await setBilling(billing.data);
    } catch (e) {}
  }

  async function getUserSchedule() {
    try {
      const schedule = await api.getUserSchedule(`?jobId=${jobId}`);
      const newSchedule = schedule.data.map((sch) => {
        return {
          ...sch,
          color: sch.courseColor,
          oldDate: sch?.date,
          oldTime: sch?.time,
          date: sch?.newDate ?? sch?.date,
        };
      });
      // if (!schedule.length) {
      setSchedule(newSchedule);
      // }
    } catch (e) {}
  }
  async function getJobById() {
    try {
      setIsLoading(true);

      const JobById = await api.getJobById(jobId);
      if (!JobById.length) {
        setJobById(JobById.data.job);
      }
      setIsLoading(false);
    } catch (e) {}
  }

  async function getReview() {
    try {
      setIsLoading(true);

      const JobById = await api.getReview(`?jobId=${jobId}`);
      setReview(JobById.data);
      setIsLoading(false);
    } catch (e) {}
  }

  function getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async function viewChat() {
    const data = {
      adminId: loggedUser._id,
      jobId: props.match.params.id,
      activityType: "Chat Log",
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
    setOpen2(true);
  }

  function classduration(startTime, endTime) {
    //     let start = moment.duration("20:00", "HH:mm");
    // let stop = moment.duration("23:15", "HH:mm");
    let startHour = startTime?.substring(0, 2);
    let endHour = endTime?.substring(0, 2);
    let startMin = startTime?.substring(3, 5);
    let endMin = endTime?.substring(3, 5);

    let HourDuration = endHour - startHour;
    let MinDuration = endMin - startMin;

    // let HourDuration = "06" - "04";
    // let MinDuration = "00" - "30";

    if (MinDuration < 0 && HourDuration === 1) {
      HourDuration = HourDuration - 1;
      MinDuration = 60 - -MinDuration;
      return HourDuration + "." + MinDuration;
    } else if (MinDuration < 0 && HourDuration > 1) {
      MinDuration = 60 - -MinDuration;
      HourDuration = HourDuration - 1;
      return HourDuration + "." + ".5" + " Hours";
    } else if (MinDuration >= 0 && HourDuration >= 1) {
      if (HourDuration === 1) {
        return HourDuration + " " + " Hour";
      } else {
        return HourDuration + " " + " Hours";
      }
    }
  }

  async function viewProfile(user, type) {
    const data = {
      adminId: loggedUser._id,
      userId: user._id,
      activityType: "View Profile",
      message: type,
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }
  function convertDays(weekDays) {
    let days = "";
    weekDays.forEach((element, index) => {
      if (element) {
        if (index === 0) {
          days += "Sun-";
        }
        if (index === 1) {
          days += "Mon-";
        }
        if (index === 2) {
          days += "Tue-";
        }
        if (index === 3) {
          days += "Wed-";
        }
        if (index === 4) {
          days += "Thu-";
        }
        if (index === 5) {
          days += "Fri-";
        }
        if (index === 6) {
          days += "Sat-";
        }
      }
    });
    return days.substring(0, days.length - 1);
  }
  const convertDateFormat = (startDate) => {
    let today = new Date(startDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      today.getDate() +
      " " +
      monthNames[today.getMonth()] +
      ", " +
      today.getFullYear()
    );
  };
  async function getAllRequests() {
    try {
      setIsLoading(true);
      const allJobs = await api.getAllRequests(`?jobId=${jobId}`);
      if (!allJobs.length) {
        await setRequests(allJobs.data);
      }
      setIsLoading(false);
    } catch (e) {}
  }

  async function getQualification() {
    try {
      setIsLoading(true);
      const qualification = await api.getQualification(
        `?request[0]?.tutorId=${jobId}`
      );
      if (!qualification.length) {
        await setQualification(qualification.data);
      }
      setIsLoading(false);
    } catch (e) {}
  }
  return job ? (
    <>
      <Header />
      <Container
        className={window.innerWidth <= 1366 ? "mt--8" : "mt--9"}
        fluid
      >
        <Row style={{ marginTop: "30px", width: "100%" }}>
          <Col xs={12} md={5} lg={5} xl={4}>
            <div className="col">
              <Row>
                <Card
                  className="shadow"
                  style={
                    job?.jobStatus !== 1
                      ? {
                          maxHeight: "950px",
                          minHeight: "650px",
                          width: "100%",
                          borderRadius: "12px",
                        }
                      : {
                          maxHeight: "950px",
                          minHeight: "506px",
                          width: "100%",
                          borderRadius: "12px",
                        }
                  }
                >
                  <CardHeader
                    style={{
                      backgroundColor: job?.course[0]?.courseColor,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: `${job?.course[0]?.courseColor}`,
                      height: "98px",
                      marginTop: -1,
                      marginLeft: -1,
                      marginRight: -1,
                      borderRadius: "12px 12px 0px 0px",
                    }}
                    className="border-0"
                  >
                    <div
                      className="Job-Post-Main"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "0px",
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        className="Course-Title-Status"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h2
                          style={{
                            color: "#fff",
                            fontSize: "22px",
                            marginBottom: "5px",
                          }}
                        >
                          {job?.course[0]?.courseName}
                          {""}
                        </h2>
                        <div style={{ paddingLeft: "20px" }}>
                          {JobStatusEnum(job?.jobStatus).view}
                        </div>
                      </div>
                      <h2
                        style={{
                          color: "#BDC7FF",
                          textAlign: "left",
                          fontSize: "18px",
                        }}
                      >
                        {job?.courseLevel}
                      </h2>
                    </div>
                  </CardHeader>
                  <CardBody style={{ padding: "0rem" }}>
                    <div>
                      <h4
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#454545",
                          marginTop: "10px",
                          marginLeft: "30px",
                        }}
                      >
                        Information
                      </h4>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Tuition Id</div>
                      {/* <div className="Info-Info">{job?._id}</div> */}
                      <div className="Info-Info">
                        # {job?._id.substring(18)}
                      </div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Duration</div>
                      <div className="Info-Info">{job?.courseDuration}</div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Start Date</div>
                      <div className="Info-Info">
                        {convertDateFormat(job.startDate)}
                      </div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Days Per Week</div>
                      <div className="Info-Info-Week">
                        {convertDays(job?.weekSchedule)}
                      </div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Timing</div>
                      <div className="Info-Info">
                        {job?.startTime + " - " + job?.endTime}
                      </div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Class Duration</div>
                      <div className="Info-Info">{job?.classDuration} Hour</div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Rate Per Hour</div>
                      <div className="Info-Info">${job?.priceperHour}</div>
                    </div>
                    <div className="Infoid-info">
                      <div className="InfoID">Address</div>
                      <div className="Info-Info">
                        {job?.addressLine}, {job?.addressUnit},{" "}
                        {job?.postalCode}{" "}
                      </div>
                    </div>

                    <hr style={{ margin: "36px 0px 30px" }}></hr>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0px 30px",
                        }}
                      >
                        <div style={{ margintop: "0px" }}>
                          <a
                            style={{
                              height: "45px",
                              width: "45px",
                              marginRight: "5px",
                              marginBottom: "1px",
                            }}
                            href="#pablo"
                            id="tooltip742438047"
                            onClick={(e) => e.preventDefault()}
                          >
                            <Avatar
                              style={{
                                width: 50,
                                height: 50,
                                marginRight: 10,
                                backgroundColor: "#ced4da",
                              }}
                              alt={job?.tutee[0]?.name}
                              src={job?.tutee[0]?.photoUrl || "/"}
                            />
                          </a>
                        </div>

                        <div
                          style={
                            job?.jobStatus !== 1
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  lineHeight: "1.1",
                                }
                              : {
                                  display: "flex",
                                  flexDirection: "column",
                                  lineHeight: "1.1",
                                }
                          }
                        >
                          <div>
                            <h5
                              style={{
                                color: "#9A9A9A",
                                fontSize: "14px",
                                // marginLeft: "12px",
                                marginBottom: "0rem",
                              }}
                            >
                              {job.userType === "Parent"
                                ? `Posted By`
                                : "Tutee"}
                            </h5>
                          </div>

                          <div>
                            <Media>
                              <Link
                                style={{ fontSize: "14px" }}
                                to={"/admin/Tutee/" + job?.tutee[0]?._id}
                                // to={`${}`}
                                // to={`/admin/Tutor/${req?.tutorId}`}
                                onClick={() => {
                                  viewProfile(job?.tutee[0]?.name, "tutee");
                                }}
                              >
                                {job?.tutee[0]?.name}
                              </Link>
                            </Media>
                          </div>
                        </div>
                      </div>

                      {job.userType === "Parent" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "0px 30px",
                          }}
                        >
                          <div style={{ margintop: "0px" }}>
                            <a
                              style={{
                                height: "45px",
                                width: "45px",
                                marginRight: "5px",
                                marginBottom: "1px",
                              }}
                              href="#pablo"
                              id="tooltip742438047"
                              onClick={(e) => e.preventDefault()}
                            >
                              <Avatar
                                style={{
                                  width: 50,
                                  height: 50,
                                  marginRight: 10,
                                  backgroundColor: "#ced4da",
                                }}
                                alt={job?.child[0]?.name}
                                src={job?.child[0]?.photoUrl || "/"}
                              />
                            </a>
                          </div>

                          <div
                            style={
                              job?.jobStatus !== 1
                                ? {
                                    display: "flex",
                                    flexDirection: "column",
                                    lineHeight: "1.1",
                                  }
                                : {
                                    display: "flex",
                                    flexDirection: "column",
                                    lineHeight: "1.1",
                                  }
                            }
                          >
                            <div>
                              <h5
                                style={{
                                  color: "#9A9A9A",
                                  fontSize: "14px",
                                  // marginLeft: "12px",
                                  marginBottom: "0rem",
                                }}
                              >
                                Tutee (Child)
                              </h5>
                            </div>

                            <div>
                              <span
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {job?.child[0]?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {job?.jobStatus !== 1 && (
                      <div className="Pending-Job-Post">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "15px",
                            marginLeft: "45px",
                          }}
                        >
                          <div
                            className="arrowStyle"
                            style={{ fontSize: "1.2rem" }}
                          >
                            <i className="fas fa-long-arrow-alt-down"></i>
                            <i className="fas fa-long-arrow-alt-up"></i>
                          </div>
                          <div>
                            <div
                              className="border-0"
                              style={{ backdisplay: "flex" }}
                            >
                              <div style={{ float: "right" }}>
                                <ThemeProvider theme={theme}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setConversationModalOpen(true);
                                    }}
                                    style={{ marginRight: "30px" }}
                                    endIcon={<SendIcon />}
                                  >
                                    Tutor-Tutee chat log
                                  </Button>
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "20px 30px",
                          }}
                        >
                          <div style={{ margintop: "10px" }}>
                            {/* <a
                              style={{
                                height: "45px",
                                width: "45px",
                                marginRight: "5px",
                              }}
                              className="avatar avatar-sm"
                              href="#pablo"
                              id="tooltip742438047"
                              onClick={(e) => e.preventDefault()}
                            > */}
                            <Avatar
                              style={{
                                width: 50,
                                height: 50,
                                marginRight: 10,
                                backgroundColor: "#ced4da",
                              }}
                              alt={job?.tutor[0]?.name}
                              src={job?.tutor[0]?.photoUrl || "/"}
                            />
                            {/* </a> */}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1.1",
                            }}
                          >
                            <div>
                              <div>
                                <h5
                                  style={{
                                    color: "#9A9A9A",
                                    fontSize: "14px",
                                    marginBottom: "0rem",
                                  }}
                                >
                                  Tutor
                                </h5>
                              </div>
                              <div>
                                <Media>
                                  <Link
                                    style={{ fontSize: "14px" }}
                                    to={"/admin/Tutor/" + job?.tutor[0]?._id}
                                    // to={`${}`}
                                    // to={`/admin/Tutor/${req?.tutorId}`}
                                    onClick={() => {
                                      viewProfile(job?.tutor[0]?.name, "tutor");
                                    }}
                                  >
                                    {job?.tutor[0]?.name}
                                  </Link>
                                </Media>
                              </div>
                            </div>
                          </div>

                          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "end",
                              marginLeft: "auto",
                            }}
                          >
                            <ThemeProvider theme={theme}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => { handleSupportModal('tutor') }}
                                endIcon={<SendIcon />}
                              >
                                Tutor Support
                              </Button>
                            </ThemeProvider>
                          </div> */}
                        </div>

                        {/* <hr style={{ marginTop: "10px" }}></hr>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div>
                            <h3
                              style={{ fontSize: "18px", marginLeft: "30px" }}
                            >
                              Rating & Review123
                            </h3>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "30px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div
                                className="stars"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#757575",
                                    fontWeight: "bold",
                                    fontSize: "80%",
                                  }}
                                >
                                  {review[0]?.rating}
                                </span>
                                <span style={{ marginLeft: "10px" }}>
                                  {" "}
                                  <ReactStars
                                    value={review[0]?.rating}
                                    isHalf={true}
                                    size={20}
                                    edit={false}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={
                                      <i className="fa fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#ffd700"
                                  />
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                fontSize: "14px",
                                marginTop: "5px",
                                marginRight: "30px",
                              }}
                            >
                              {convertDateFormat(review[0]?.createdAt)}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            marginBottom: "42px",
                            fontSize: "70%",
                          }}
                        >
                          {review[0]?.feedback}
                          <Link>
                            <span style={{ marginLeft: "30px" }}>
                              Read More
                            </span>
                          </Link>
                        </div> */}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Row>
              {job?.jobStatus === 1 ? (
                <div>
                  <Row>
                    <Card
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        borderRadius: "12px",
                        height: "253px",
                      }}
                      className="border-0"
                    >
                      <div className="Tutor-Requests-Main">
                        <div className="Tutor-Requests-Top">
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#454545",
                              fontFamily: "Open Sans",
                              marginBottom: "0rem",
                            }}
                          >
                            Tutor Requests
                          </p>
                        </div>
                        <div className="Tutor-Requests-Name-Qualification PrimaryScrollQualification1111">
                          <Table
                            className="align-items-center table-flush PrimaryScrollQualification1111"
                            responsive
                          >
                            <thead className="thead-light sticky-top">
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    fontSize: "0.521vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  Name
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr
                      style={{
                        width: "100%",
                        height: "100%",
                        marginLeft: "10rem",
                      }}
                    >
                      <td colSpan={9}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      </td>
                    </tr> */}
                              {requests.length >= 0 ? (
                                requests.map((req) => (
                                  <tr>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        {/* <a
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                          }}
                                          className="avatar rounded-circle mr-3"
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        > */}
                                        <Avatar
                                          style={{
                                            width: 50,
                                            height: 50,
                                            marginRight: 10,
                                            backgroundColor: "#ced4da",
                                          }}
                                          alt={req?.tutorDetail[0]?.name}
                                          src={
                                            req?.tutorDetail[0]?.photoUrl || "/"
                                          }
                                        />
                                        {/* </a> */}
                                        <Media>
                                          <Link
                                            style={{ fontSize: "14px" }}
                                            to={"/admin/Tutor/" + req?.tutorId}
                                            // to={`${}`}
                                            // to={`/admin/Tutor/${req?.tutorId}`}
                                            onClick={() => {
                                              viewProfile(
                                                req?.tutorDetail[0]?.name,
                                                "tutor"
                                              );
                                            }}
                                          >
                                            {req?.tutorDetail[0]?.name}
                                          </Link>
                                        </Media>
                                      </Media>
                                    </th>
                                  </tr>
                                ))
                              ) : (
                                <tr
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    marginLeft: "10rem",
                                  }}
                                >
                                  <td colSpan={9}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ff6700"}
                                        height={30}
                                        width={30}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Card>
                  </Row>
                </div>
              ) : (
                <Row>
                  <Card
                    style={{ width: "100%", marginTop: "20px" }}
                    className="border-0"
                  >
                    <Map address={job?.addressLine} />
                  </Card>
                </Row>
              )}
            </div>
          </Col>

          <Col xs={12} md={6} lg={6} xl={8}>
            <div className="col" style={{ height: "100%" }}>
              {/* <Row>
                <Card className="shadow" style={{ width: "100%" }}>
                  <CardHeader className="border-0">
                    <h3 className="mb-0"> Progress</h3>
                  </CardHeader>
                  <Table className="align-items-center " responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">MONTH</th>
                        <th scope="col">CLASSES</th>
                        <th scope="col">PROGRESS</th>
                        <th scope="col">RATING</th>
                        <th scope="col">TUTEE FEES</th>
                        <th scope="col">TUOTR SALARY</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {billing?.result[0]?.invoices?.map((inv) => (
                        <tr style={{ borderBottom: "1px solid #d3d3d3" }}>
                          <td style={{ fontWeight: "bold" }}>{inv.month}</td>

                          <td>{billing.result[0].totalClasses}</td>

                          <td>
                            <div className="d-flex align-items-center">
                              <span className="mr-2">{inv?.jobDetails?.completedPerc?.toFixed(2)}</span>
                              <div>
                                <Progress
                                  max="100"
                                  value={inv?.jobDetails?.completedPerc}
                                  barClassName={inv?.jobDetails?.completedPerc?.toFixed(2) > 50 ? "bg-success" : "bg-danger"}                                 />
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="stars">
                              <span style={{ color: "#454545" }}>4.0</span>{" "}
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star"></span>
                            </div>
                          </td>

                          <td style={{ border: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                            >
                              <a
                                style={{
                                  color: "9A9A9A",
                                  fontWeight: "bold",
                                  margin: "0px 20%"
                                }}
                              >
                               ${inv?.amount}
                              </a>
                              <span style={{ color: "grey" }}>
                                {inv?.dueOn}
                              </span>
                            </div>
                          </td>

                          <td style={{ border: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                            >
                              <a
                                style={{
                                  color: "9A9A9A",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                $144
                              </a>
                              <span
                                style={{ color: "grey", textAlign: "center" }}
                              >
                                Pending
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Row> */}
              <Row style={{ height: "100%" }}>
                <Card
                  className="shadow"
                  style={{
                    minHeight: "780px",
                    // marginTop: "20px",
                    width: "100%",
                  }}
                >
                  {/* <div className="tuitionDetails-rescedullebtn">
                    <Link>
                      <div
                        onClick={() => setConversationModalOpen(true)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          width: "151px",
                          height: "26px",
                          color: "#FF6700",
                          marginLeft: "-5px",
                          border: "0.5px solid #FF6700",
                          paddingBottom: "5px",
                          backgroundColor: "#ffffff",
                        }}
                        className="buttonTabs1"
                      >
                        <img
                          alt="..."
                          style={{ paddingRight: "5px", marginTop: "3px" }}
                          src={
                            require("../../assets/img/icons/common/calendarrefresh.png")
                              .default
                          }
                        />
                        Reschedule class
                      </div>
                    </Link>
                    
                  </div> */}
                  <Schedule
                    schedule={schedule}
                    getUserSchedule={getUserSchedule}
                    jobPostColor={job?.course[0]?.courseColor}
                  />
                </Card>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => setOpen(!isOpen)}
      >
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>Reschedule class</h1>
          <p style={{ color: "#9A9A9A" }}>
            Choose from below options to reschedule a class
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 15px",
            fontWeight: "bold",
          }}
          className="modal-body"
        ></div>

        <div style={{ margin: "-15px 30px", marginLeft: "60px" }}>
          <h5> Reschedule type</h5>

          <div
            style={{
              marginTop: "15px ",
              marginBottom: "15px ",
              marginRight: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="custom-control custom-checkbox mb-3 ">
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
              />
              <label className="custom-control-label " htmlFor="customCheck1">
                <span style={{ marginLeft: "-20px" }}> Date</span>
              </label>
            </div>
            <div
              style={{ marginLeft: "-40px" }}
              className="custom-control custom-checkbox mb-3"
            >
              <input
                className="custom-control-input"
                defaultChecked
                id="customCheck2"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="customCheck2">
                <span style={{ marginLeft: "-20px" }}> Time</span>
              </label>
            </div>
            <div
              style={{ marginLeft: "-32px" }}
              className="custom-control custom-checkbox mb-3"
            >
              <input
                className="custom-control-input"
                defaultChecked
                id="customCheck3"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="customCheck3">
                <span style={{ marginLeft: "-20px" }}> Both </span>
              </label>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <div style={{ margin: "10px 30px" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "10px",
                marginRight: "10px",
                marginBottom: "10px",
                width: "80%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
            <div style={{ marginTop: "-43px", marginLeft: "42%" }}>
              <i
                style={{ color: "#9A9A9A" }}
                className="fas fa-arrow-right"
              ></i>
            </div>
          </div>
          <div style={{ marginTop: "-15.5%", marginLeft: "50%" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "10px",
                marginRight: "10px",
                marginBottom: "10px",
                width: "150%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
          </div>
        </div>

        <div style={{ marginLeft: "30px" }}>
          <div style={{ margin: "10px 30px" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "10px",
                marginRight: "10px",
                marginBottom: "10px",
                width: "80%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
            <div style={{ marginTop: "-43px", marginLeft: "42%" }}>
              <i
                style={{ color: "#9A9A9A" }}
                className="fas fa-arrow-right"
              ></i>
            </div>
          </div>
          <div style={{ marginTop: "-15.5%", marginLeft: "50%" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "10px",
                marginRight: "10px",
                marginBottom: "10px",
                width: "150%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Link
            style={{ width: "100%", margin: "20px 10px", marginLeft: "-18px" }}
          >
            <div
              style={{ height: "50px", paddingTop: "12px" }}
              className="modalFooter"
            >
              Update Schedule
            </div>
          </Link>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen1}
        style={{ width: "999px !important", height: "802px !important" }}
        toggle={() => setOpen1(!isOpen1)}
      >
        <div style={{ marginTop: "35px", marginLeft: "30px" }}>
          <h1>Review</h1>
          <div>
            <span style={{ color: "#757575" }}>4.0 </span>

            <span
              style={{ color: "#FFC22B" }}
              className="fa fa-star checked"
            ></span>
            <span
              style={{ color: "#FFC22B" }}
              className="fa fa-star checked"
            ></span>
            <span
              style={{ color: "#FFC22B" }}
              className="fa fa-star checked"
            ></span>
            <span style={{ color: "#FFC22B" }} className="fa fa-star"></span>
            <span style={{ color: "#FFC22B" }} className="fa fa-star"></span>
          </div>
          <div
            style={{ marginTop: "10px", marginRight: "15px", color: "#9A9A9A" }}
          >
            <p>
              This is a really skilled and professional tutor. I will recommend
              him for the Maths tuition because he has really nice concepts and
              has the ability to transform them into the childrens mind by
              explaining them with very appropriate examples
            </p>
          </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            marginBottom: "30px",
            marginLeft: "30px",
          }}
        >
          <span style={{ color: "#9A9A9A", fontWeight: "bold" }}>
            Taken after Class #2
          </span>
          <span
            style={{ color: "#9A9A9A", fontWeight: "bold", marginLeft: "35%" }}
          >
            04 Aug, 2021
          </span>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen2}
        toggle={() => setOpen2(!isOpen2)}
        size="xl"
      >
        <Chat />
      </Modal>
      <Modal
        className="modal-dialog-centered conversiationModal"
        style={{ maxWidth: "999px !important", maxHeight: "802px !important" }}
        isOpen={conversationModalOpen}
        toggle={() => setConversationModalOpen(!conversationModalOpen)}
      >
        <ConversationServiceProvider>
          <Conversation job={job} />
        </ConversationServiceProvider>
      </Modal>
    </>
  ) : (
    <div style={{ height: "100vh" }}>
      <Header />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "70%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ReactLoading
          type={"spin"}
          color={"#ff6700"}
          height={60}
          width={60}
          marginTop={-35}
        />
      </div>
    </div>
  );
};

export default Tables;
