import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Row,
  UncontrolledTooltip,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import ReactStars from "react-rating-stars-component";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "./index.css";
import ReviewListModal from "components/ReviewListModal";
import { SupportContext } from "../../context/supportContext";
const localizer = momentLocalizer(moment);

const ProfileCardTutor = ({ user, totalEarnings = 0, editUser, getUsersById }) => {
  const [state, setState] = useState({
    reviewModalIsOpen: false,
  });
  const { openModal } = useContext(SupportContext);
  const handleReviewModal = () => {
    setState((prev) => ({
      ...prev,
      reviewModalIsOpen: !prev.reviewModalIsOpen,
    }));
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return (
    <div className="card-profile shadow ProfileMainCard">
      <div style={{ padding: "1.563vw 1.563vw 0vw" }}>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%", padding: "0px 0px 1.458vw" }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
              color: "#FF3A3A",
              height: "1.458vw",
              width: "5.2vw",
              fontSize: "0.629vw",
              fontWeight: 400,
              borderRadius: "6px",
              border: "0.5px solid #FF3A3A",
            }}
            className="float-right"
            color="default"
            onClick={() => {
              openModal({ user: user?.user });
            }}
          >
            Message
          </button>
          <div className="card-profile-image">
            {user?.user?.photoUrl ? (
              <img
                src={user?.user?.photoUrl}
                style={{
                  width: "9.375vw",
                  height: "9.479vw",
                  borderRadius: "50%",
                  position: "absolute",
                  right: "-4.479vw",
                  bottom: "-5vw",
                }}
              />
            ) : (
              <Avatar
                style={{
                  width: "9.375vw",
                  height: "9.479vw",
                  borderRadius: "50%",
                  backgroundColor: "#ced4da",
                  position: "absolute",
                  right: "-4.479vw",
                  bottom: "-3.333vw",
                }}
                alt={user?.user?.photoUrl}
                src="/"
              />
            )}
          </div>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
              color: "#FF3A3A",
              height: "1.458vw",
              width: "5.2vw",
              fontSize: "0.629vw",
              fontWeight: 400,
              borderRadius: "6px",
              border: "0.5px solid #FF3A3A",
            }}
            className="float-right"
            color="default"
            href="#pablo"
            onClick={async () => {
              editUser(user?.user?._id, {
                user: {
                  status:
                    user?.user?.status === "Active" ? "InActive" : "Active",
                },
              }).then(() => getUsersById());
            }}
          >
            {user?.user?.status === "Active" ? "Block User" : "Unblock User"}
          </button>
        </div>
      </div>
      <div style={{ padding: "1.302vw 1.563vw 1.146vw" }}>
        <div className="ProfileInfoCard">
          <div className="ProfileUserInfoCard">
            <div className="ProfileInfoCardValueMLeft">
              {user?.jobDetails ? (
                <div className="heading ProfileCard1845">
                  {user?.jobDetails?.ongoing}
                </div>
              ) : (
                <Stack spacing={10}>
                  <Skeleton variant="rectangular" width={30} height={20} />
                </Stack>
              )}
              <div className="description ProfileCard149A">Ongoing Tution</div>
            </div>
            <div className="ProfileInfoCardValueNoM">
              {user?.jobDetails ? (
                <div className="heading ProfileCard1845">
                  {user?.jobDetails?.total}
                </div>
              ) : (
                <Stack spacing={10}>
                  <Skeleton variant="rectangular" width={30} height={20} />
                </Stack>
              )}

              <div className="description ProfileCard149A">Total Tution</div>
            </div>
            <div className="ProfileInfoCardValueMRight">
              {user?.jobDetails ? (
                <div className="heading ProfileCard1845">${totalEarnings}</div>
              ) : (
                <Stack spacing={10}>
                  <Skeleton variant="rectangular" width={30} height={20} />
                </Stack>
              )}
              <div className="description ProfileCard149A">Total Earnings</div>
            </div>
          </div>
        </div>

        <div>
          <div className="ProfileCardMT30">
            {user?.user ? (
              <h3
                className="border-0 ProfileCard2045"
                style={{
                  marginBottom: "4px",
                  textAlign: "left",
                  fontWeight: 600,
                }}
              >
                {user?.user?.name + " "}
                <span
                  className="font-weight-light ProfileCard169A"
                  style={{ textAlign: "left", fontWeight: 600 }}
                >
                  {" "}
                  - TUTOR
                </span>
              </h3>
            ) : (
              <Stack spacing={10}>
                <Skeleton variant="rectangular" width={90} height={20} />
              </Stack>
            )}
            {user?.user ? (
              <div
                style={{
                  fontSize: "0.833vw",
                  color: "#9A9A9A",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div className="tuteeIdBlock">ID: {user.user?.memberId}</div>{" "}
                {user?.user?.gender ? user?.user?.gender + ", " : null}
                {user?.user?.dob ? getAge(user?.user?.dob) : ""}
              </div>
            ) : (
              <div style={{ marginTop: "15px" }}>
                <Stack spacing={10}>
                  <Skeleton variant="rectangular" width={90} height={20} />
                </Stack>
              </div>
            )}
          </div>
          <div
            onClick={handleReviewModal}
            style={{
              marginTop: "-3vw",
              display: "flex",
              flexDirection: "column",
              float: "right",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <td style={{ float: "right", fontWeight: "bold" }}>
                {user?.reviewDetails?.rating ?? 0.0} &nbsp;
              </td>
              <td style={{ float: "right" }} className="stars">
                {parseFloat(user?.reviewDetails?.rating) >= 0.0 && (
                  <ReactStars
                    value={user?.reviewDetails?.rating}
                    isHalf={true}
                    size={"20rem"}
                    edit={false}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                )}
              </td>
            </div>
            <div
              style={{ float: "right", color: "#9A9A9A" }}
              className="h5 font-weight-300"
            >
              <i className="ni location_pin ml-5" />(
              {user?.reviewDetails?.totalReviews}{" "}
              {user?.reviewDetails?.totalReviews > 1 ? "Reviews" : "Review"})
            </div>
          </div>
          <hr className="ProfileCardHR" />
          <div className="ProfileColor9A">
            <i
              style={{ fontSize: "0.833vw", color: "#ff6700" }}
              className="fas fa-envelope"
            ></i>{" "}
            <span style={{ fontSize: "0.729vw" }} className="description">
              {user?.user?.email}
            </span>{" "}
            {user?.user?.isEmailActive && (
              <i className="fas fa-check-circle ProfileColor12BF0F"></i>
            )}
          </div>
          <div style={{ marginTop: "0.1vw" }}>
            <i
              style={{ color: "#ff6700", fontSize: "0.833vw" }}
              className="fas fa-phone-alt"
            ></i>{" "}
            {/* <span style={{ fontSize: "0.729vw" }} className="ProfileColor9A"> */}
            <span className="ProfileColor9A">
              {" "}
              {user?.user?.mobilePhone ? user?.user?.mobilePhone : "--- -----"}
            </span>{" "}
            {user?.user?.isMobileActive && (
              <i
                style={{ fontSize: "0.833vw" }}
                className="fas fa-check-circle ProfileColor12BF0F"
              ></i>
            )}
          </div>

          <div style={{ marginTop: "0.260vw" }}>
            <i
              style={{ color: "#ff6700", fontSize: "0.833vw" }}
              className="fas fa-map-marker-alt"
            ></i>{" "}
            <span style={{ fontSize: "0.729vw" }} className="ProfileColor9A">
              {user?.user?.addressUnit
                ? user?.user?.addressUnit +
                  ", " +
                  user?.user?.addressLine +
                  ", " +
                  user?.user?.postalCode
                : " Not Available"}
            </span>
          </div>
        </div>
      </div>

      <ReviewListModal
        isOpen={state.reviewModalIsOpen}
        handleModal={handleReviewModal}
        reviewsList={user?.reviewList}
      />
    </div>
  );
};
export default ProfileCardTutor;
